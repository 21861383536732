<template>
  <div>
    <!-- Todo:aqui hay que poner el componente de ecommerce para que salgan los ultimos lanzamientos con filtros-->
    <section class="bg-grey bg bg-menu">
      <b-row class="content-wrapper row">
        <b-col
            xl="5"
            md="12"
        >
          <h1 class="title-products text-white mt-4 mb-xl-3 text-xl-left">
            Confirmación de compra
          </h1>
        </b-col>
        <b-col
            xl="6"
            md="12"
            class="text-sm-center"
        >
          <p class="text-white mt-xl-4 mt-sm-1 mb-3 ">
            ¡Gracias por tu compra! Pronto recibirás tus productos promocionales. ¡Disfrútalos al máximo!
          </p>
        </b-col>
      </b-row>
    </section>

    <section class="bg-grey bg">
      <b-row class="content-wrapper row pt-5">
        <b-col
            class="d-block m-auto"
            xl="10"
        >
          <b-card>
            <b-row>
              <b-col
                  xl="12">
                <h2 class="mb-2">Para realizar un seguimiento de tu pedido, visita la sección 'Mis Compras' en “Mi cuenta”</h2>
              </b-col>
              <b-col xl="12">
                <div class="card-data">
                  <div class="title-card">
                    <span>Fecha de compra: <b>{fecha compra }</b></span>
                  </div>
                  <div class="body-card">
                    <b-row>
                      <b-col
                          md="12"
                          lg="2">
                        <img
                            loading="lazy"
                            src="@/assets/images/home/vaso-lth.png"
                            alt="Avatar"
                            class="img-fluid d-block m-auto"
                        >
                      </b-col>
                      <b-col
                          class="mb-2"
                          md="12"
                          lg="3">
                        <p><b>Bolígrafo de plástico MAYA</b></p>
                        <p>8 paquetes con 10 u. c/u</p>
                        <p>Logo: LTH</p>
                        <p>Color: Negro</p>
                      </b-col>
                      <b-col
                          class="mb-2"
                          md="12"
                          lg="3">
                        <p class=""><b>Dirección de envío:</b></p>
                        <p>Ana Rodriguez,
                          México 523
                          15 de mayo
                          Monterrey, Nuevo León
                          65438</p>
                      </b-col>
                      <b-col
                          class="mb-2"
                          md="12"
                          lg="3">
                        <p><b># Pedido:</b> 83450293</p>
                        <p><b>Guía:</b> <a class="text-primary" href="">745030124</a></p>
                        <p><b>Pago:</b> $1,680</p>
                        <p><b>Método de pago:</b> Visa que termina en 2345</p>
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import {
  BRow,
  BCol, BCard,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    BCard,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      contentWidth: this.$store.state.appConfig.layout.contentWidth,
      search: '',
    }
  },
  created() {
    this.$store.commit('appConfig/UPDATE_CONTENT_WIDTH', 'boxed')
  },
  destroyed() {
    this.$store.commit('appConfig/UPDATE_CONTENT_WIDTH', this.contentWidth)
  },
  mounted() {
    this.removeHeaderNavbarShadow()
  },
  methods: {
    searchProduct() {
      this.$router.push({ name: 'products', params: { searchActive: 'true' } })
    },
    removeHeaderNavbarShadow() {
      const element = document.querySelector('.header-navbar-shadow')
      if (element) {
        element.classList.remove('header-navbar-shadow')
      }
    },
  },
}

</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/_variables.scss';
@import '~@core/scss/base/components/_variables-dark.scss';
@import "~@core/scss/base/pages/app-ecommerce.scss";

//New Arribals
.card-data{
  margin-top: 10px;
  background-color: #fff;
  border: 1px solid #979593;
  border-radius: 5px;
}
.title-card{
  padding: 10px;
  border-bottom: 1px solid #979593;
  background-color: #f2f2f2;
  border-radius: 5px 5px 0px 0px;
}
.body-card{
  padding: 10px;
}
.bg-menu {
  margin-top: -45px;
  background-color: #fff!important;
}
.bg-menu {
  margin-top: -45px;
  background: linear-gradient(to right, #F6A21B, #F6A21B, #EC2C39, #4A2268, #4A2268);

}
.header-navbar-shadow{
  background:none!important;
}
//New Arribals

.section-who{
  padding: 0!important;
  h3{
    color: #4A2268!important;
    font-weight: 700;
  }
  .s-img{
    position: absolute;
    bottom: -10px;
    right: -23px;
    width: 270px;
  }
  p{
    max-width: 500px;
    font-size: 18px;
    line-height: 27px;
    padding-left: 35px;
  }
}
.card-team{
  overflow: hidden;
  .card-body{
    padding: 0;
  }
  .card{
    margin-bottom: 0;
  }
}
.icon_item{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  text-align: center;
  justify-content: center;
  .item {
    width: calc(100% / 9);
    margin-bottom: 25px;
    text-align: center;
    font-weight: bold;
    color: #1d4079;
    -webkit-transition: all .3s ease 0s;
    transition: all .3s ease 0s;
    &:hover{
      -webkit-filter: drop-shadow(0px 0px 30px rgba(29, 64, 121));
    }
  }
  img{
    width: 65px;
    display: block;
    margin: 0 auto;
  }

}
.max-he-home{
  max-height: 253px;
}
.box-text {
  display: block;
  margin: 0 auto;
}
.btn-white-red{
  border-color: #ffffff !important;
  background-color: #ffffff !important;
  color: #ea5455!important;
  font-weight: 700;
}

.btn-white-blue{
  border-color: #ffffff !important;
  background-color: #ffffff !important;
  color: rgba(29,64,121,1)!important;
  font-weight: 700;
}

.bg-red-1{
  background: rgb(142, 45, 226);
  background: linear-gradient(149deg, rgba(142, 45, 226,1) 0%, rgba(74,0, 224,1) 80%);
  .card-body{
    align-items: center !important;
    display: flex !important;
  }
}

.bg-red-2{
  background: rgb(249, 83, 198);
  background: linear-gradient(149deg, rgba(249, 83, 198,1) 0%, rgba(74,0, 224,1) 80%);
  .card-body{
    align-items: center !important;
    display: flex !important;
  }
}
.bg-red-3 {
  background: rgb(249, 83, 198);
  background: linear-gradient(149deg, rgba(249, 83, 198, 1) 0%, rgba(74, 0, 224, 1) 80%);
}
.bg-red-4 {
  background: rgb(238, 9, 121);
  background: linear-gradient(149deg, rgba(238, 9, 121, 1) 0%, rgba(255, 106, 0, 1) 80%);
}
.title-products{
  color: #43413D;
  font-size: 30px ;
  text-align: center;
}
.dark-layout{
  .collapse-title{
    color: $theme-dark-headings-color!important;
  }
  .blockquote{
    background-color: $theme-dark-input-bg;
    border:solid 1px $theme-dark-headings-color;
  }
  .title-products{
    font-size: 30px;
    font-weight: 700;
    color: $theme-dark-headings-color!important;
  }
  .bg-grey{
    background-color: $theme-dark-card-bg;
  }
  .img-overlay {
    background-color: $theme-dark-input-bg;
  }
}
.dot {
  width: 400px; height: 400px;
  background: radial-gradient(ellipse at center, #fff,rgba(0,0,0,.0));
}
.img-product {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #fff;
  display: block;
  margin: 0 auto;
}

.img-product-2 {
  height: 200px;
  background-repeat: no-repeat;
  background-position: center;
  display: block;
  margin: 0 auto;
}
.img-product-2-s-1{
  background-size: 180px auto;
}

.img-product-2-s-2{
  background-size: auto 190px;
}

.img-product-2-s-3{
  background-size: auto 185px;
}
.img-product-2-s-4{
  background-size: auto 173px;
}
.yourImg {
  border-radius: 50%;
}
.text-box-title{
  font-size: 30px;
}
.box-image{
  height: 100px;
  width: 100px;
  display: block;
  margin: 0 auto;
}

.dark-layout{
  .icon-img-home{
    background-color: #d0d2d6;
  }
  .text-item{
    color: #d0d2d6!important;
  }
}
.media-body{
  p {
    margin-top: 0;
    margin-bottom: 0px!important;
  }
}
.text-item{
  font-size: 15px;
  font-weight: 700;
  color: #686868!important;

}
.media-center{
  -webkit-box-align: center!important;
  -ms-flex-align: center!important;
  align-items: center!important;
}
.icon-img-home{
  width: 100%;
  height: 65px;
  background-color: $primary;
}

.hieleras-img{
  mask: url('~@/assets/images/icos/hielera.svg') no-repeat center;
  -webkit-mask: url('~@/assets/images/icos/hielera.svg') no-repeat center;
}

.spray-img{
  mask: url('~@/assets/images/icos/spray.svg') no-repeat center;
  -webkit-mask: url('~@/assets/images/icos/spray.svg') no-repeat center;
}

.textiles-img{
  mask: url('~@/assets/images/icos/t-shirt.svg') no-repeat center;
  -webkit-mask: url('~@/assets/images/icos/t-shirt.svg') no-repeat center;
}

.star-img{
  mask: url('~@/assets/images/icos/star.svg') no-repeat center;
  -webkit-mask: url('~@/assets/images/icos/star.svg') no-repeat center;
}

.termos-img{
  mask: url('~@/assets/images/icos/thermos.svg') no-repeat center;
  -webkit-mask: url('~@/assets/images/icos/thermos.svg') no-repeat center;
}

.mochilas-img{
  mask: url('~@/assets/images/icos/backpack.svg') no-repeat center;
  -webkit-mask: url('~@/assets/images/icos/backpack.svg') no-repeat center;
}

.bocinas-img{
  mask: url('~@/assets/images/icos/speaker.svg') no-repeat center;
  -webkit-mask: url('~@/assets/images/icos/speaker.svg') no-repeat center;
}

.power-img{
  mask: url('~@/assets/images/icos/power-bank.svg') no-repeat center;
  -webkit-mask: url('~@/assets/images/icos/power-bank.svg') no-repeat center;
}

.herramientas-img{
  mask: url('~@/assets/images/icos/tools.svg') no-repeat center;
  -webkit-mask: url('~@/assets/images/icos/tools.svg') no-repeat center;
}
.exclusivos-img{
  mask: url('~@/assets/images/icos/lth.svg') no-repeat center;
  -webkit-mask: url('~@/assets/images/icos/lth.svg') no-repeat center;
}

.otros-img{
  mask: url('~@/assets/images/icos/headphone.svg') no-repeat center;
  -webkit-mask: url('~@/assets/images/icos/headphone.svg') no-repeat center;
}
.list-categories{
  .media{
    margin-bottom: 10px;
  }

}

.flex-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  h2{
    font-size: 16px;
    font-weight: 700;
  }

  .custom-col {
    flex: 0 0 calc(33.333% - 20px);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    max-width: 200px;
    margin: 0 auto;

    &:last-child {
      margin-right: 0;
    }
  }
}
#carousel-example-generic {
  width: 100vw; /* Esto establece el ancho al 100% del viewport */
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

.gradient-border {
  display: inline-block;
  padding: 2px; // Esto se comporta como el borde
  background: linear-gradient(to right, #F6A21B, #EC2C39, #4A2268);
  border-radius: 7px; // 2px (borde) + 5px (border-radius del botón) = 7px
  transition: background 0.3s;

  .category-button {
    display: block;
    background-color: white;
    border: none;
    border-radius: 5px;
    width: 130px!important;
    transition: background-color 0.3s, color 0.3s;

    &:hover {
      background-color: transparent;
      color: white;
    }
  }
}

.bg {
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

.bg-grey{
  background-color: #f2f2f2;
}.bg-grey{
   background-color: #f2f2f2;
 }

.content-wrapper {
  margin-left: 50vw;
  margin-right: 50vw;
  box-sizing: border-box;

  > * {
    box-sizing: content-box;
  }
}
.img-overlay {
  .float-discont{
    z-index: 5!important;
    position: absolute;
    color: #fff;
    font-weight: 700;
    right: 10px;
    top: 10px;
    padding: 1px 6px;
    background-color: #EC2C39;
  }
  background-color: white;
  border: 1px solid #c2c2c2;
  position: relative; // necesitamos posición relativa para posicionar absolutamente el texto
  border-radius: 5px;
  margin-bottom: 15px;
  .img-overlay-image {
    width: 100%;
    height: auto;
    display: block;
  }

  .overlay {
    position: absolute; // esto permitirá que el texto se superponga sobre la imagen
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center; // centrar horizontalmente
    align-items: center; // centrar verticalmente
    opacity: 0; // ocultar por defecto
    background-color: rgba(0, 0, 0, 0.4); // fondo negro con 50% de transparencia
    transition: opacity 0.3s ease-in-out; // transición suave al hacer hover

    .text {
      color: white;
      text-decoration: underline;
      font-weight: 700;
    }
  }

  &:hover {
    .overlay {
      opacity: 1; // mostrar al hacer hover
    }
  }
}
.product-fav{
  .image-container {
    position: relative;
    width: auto; // O define un ancho específico si lo necesitas
    img {
      width: 100%;
      height: auto;
      display: block;
    }

    .overlay-content {
      text-align: left;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding-bottom: 20px;
      padding-left: 15px;
      background: linear-gradient(to right, rgba(0, 0, 0, 0.5), transparent);
      .category-text {
        color: white;
        font-size: 2em;
        margin-bottom: 10px;
      }
      .btn {
        max-width: 160px;
      }
    }
  }
}

.blockquote{
  background-color: #fff;
  padding: 20px 30px;
  font-size: 15px;
  height: 100%;
  p{
    margin-bottom: 0px;
    margin-top: 5px;
    color: #940C4A!important;
  }
  .quote-mark{
    color: #940C4A;
    line-height: 0px;
    font-size: 25px;
  }
}

@media (max-width: 767px) {
  .float-points{
    display: none;
  }
  .cr-values{
    display: none!important;
  }
  .catogory{
    h2{
      color: #43413D;
      font-size: 25px ;
    }
  }
}

@media (min-width:1200px) and (max-width: 1480px) {
  .img-product{
    width: 245px!important;
    height: 245px!important;
    background-size: 120px!important;
  }
}
@media (max-width:991px){
  .gradient-border {
    margin-bottom: 20px;
  }
  .mb-box-home{
    margin-bottom: 30px;
  }
  .icon_item {
    .item{
      width: calc(100% / 5);
    }
  }
}

@media (max-width:1500px){
  .container-who{
    padding: 18px 70px;
  }
  .section-who{

    img{
      display: none;
    }
    h3{
      margin-top: 30px;
    }
    p{
      padding: 0;
      max-width: none;
    }
  }
}
@media (max-width:576px){
  .icon_item {
    .item{
      width: calc(100% / 3);
    }
  }
}

</style>
